<template>
  <div class="about">
    <van-sticky>
      <van-nav-bar :title="title" />
    </van-sticky>
    <van-image lazy-load :src="require('../assets/about/1.jpg')" />
    <van-image lazy-load :src="require('../assets/about/2.jpg')" />
    <van-image lazy-load :src="require('../assets/about/13.jpg')" />
    <van-image lazy-load :src="require('../assets/about/14.jpg')" />
    <van-image lazy-load :src="require('../assets/about/15.jpg')" />
    <van-image lazy-load :src="require('../assets/about/16.jpg')" />
    <van-image lazy-load :src="require('../assets/about/3.jpg')" />
    <van-image lazy-load :src="require('../assets/about/4.jpg')" />
    <van-image lazy-load :src="require('../assets/about/5.jpg')" />
    <van-image lazy-load :src="require('../assets/about/6.jpg')" />
    <van-image lazy-load :src="require('../assets/about/7.jpg')" />
    <van-image lazy-load :src="require('../assets/about/8.jpg')" />
    <van-image lazy-load :src="require('../assets/about/9.jpg')" />
    <van-image lazy-load :src="require('../assets/about/10.jpg')" />
    <van-image lazy-load :src="require('../assets/about/11.jpg')" />
    <van-image lazy-load :src="require('../assets/about/12.jpg')" />

    <div style="height: 80px"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: { headImg: require("../assets/head.png"), nickname: "---" },
      Authorization: localStorage.getItem(window.lsn),
      title: "医院简介",
    };
  },
  mounted() {},
  methods: {
    onClickLeft() {
      history.back();
    },
  },
};
</script>
<style lang="scss">
.about {
  color: #000;
  img {
    width: 100%;
  }
}
</style>
